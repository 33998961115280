<template>
  <div class="my-5">
    <h2 class="subheading grey--text" my-5>Tickets</h2>
    <v-container fluid>
      <v-row align="end">
        <v-col cols="6">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="px-2" @click="getTickets">refresh</v-icon>
            </template>
            <span>{{ $t('Refresh') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('Search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="tickets"
            :search="search"
            class="elevation-1"
            sort-by="ticketNumber"
            :sort-desc="sortDescending"
          >
            <template v-slot:[`item.validFrom`]="{ item }">
              <span>{{ formatDate(item.validFrom) }}</span>
            </template>
            <template v-slot:[`item.validTo`]="{ item }">
              <span>{{ formatDate(item.validTo) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip small :color="getStatusColor(item.status)" dark>{{
                item.status.name
              }}</v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div v-if="canBeInvalidated(item)">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="confirmTicketInvalidate(item)">work_off</v-icon>
                  </template>
                  <span>{{ $t('Invalidate') }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <!-- error snackbar -->
          <v-snackbar color="#D50000" v-model="errorSnackbar" :timeout="4000">
            <span>{{ $t('An error occured on fetching tickets list') }}</span>
            <v-btn icon dark @click="errorSnackbar = false">
              <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- error snackbar -->
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="ticketInvalidateConfirm" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t('Confirm') }}</v-card-title>
        <v-card-text>{{ $t('Are you sure to invalidate this ticket?') }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="ticketInvalidateConfirm = false">{{
            $t('Cancel')
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="invalidateTicket">{{ $t('Invalidate') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TicketList',
  data() {
    return {
      search: '',
      loading: false,
      sortDescending: true,
      errorSnackbar: false,
      ticketInvalidateId: 0,
      ticketInvalidateConfirm: false,
      headers: [
        {
          text: this.$t('Ticket Number'),
          align: 'left',
          value: 'ticketNumber',
          sortable: false,
        },
        {
          text: this.$t('Booking Number'),
          value: 'bookingNumber',
          sortable: true,
        },
        { text: this.$t('OTA Number'), value: 'otaNumber', sortable: true },
        { text: this.$t('Guest Name'), value: 'guestName', sortable: true },
        { text: this.$t('Code'), value: 'code', sortable: false },
        { text: this.$t('Valid From'), value: 'validFrom', sortable: true },
        { text: this.$t('Valid To'), value: 'validTo', sortable: true },
        { text: this.$t('Status'), value: 'status', sortable: true },
        { text: this.$t('Actions'), value: 'action', align: 'center' },
      ],
      tickets: [],
    }
  },
  methods: {
    ...mapActions({
      invokeGetTickets: 'ticket/getTickets',
      invokeInvalidate: 'ticket/invalidate',
    }),
    formatDate(dateStr) {
      if (!dateStr) return dateStr

      const date = new Date(dateStr)

      if (!date) return dateStr

      const day = date.getDate()
      const dayStr = day < 10 ? '0' + day : day
      const month = date.getMonth() + 1
      const monthStr = month < 10 ? '0' + month : month
      const year = date.getFullYear()

      return `${dayStr}/${monthStr}/${year}`
    },
    getStatusColor(status) {
      // Invalidated
      if (status.value === 0) return '#01579B'
      // Not Active
      else if (status.value === 1) return '#efbf2d'
      // Active
      else if (status.value === 2) return '#4cd964'
      // Not valid yet
      else if (status.value === 4) return '#ff9500'
      // Expired
      else return '#ff3b30'
    },
    canBeInvalidated(ticket) {
      // able to invalidate only Not Active
      return ticket.status.value === 1
    },
    confirmTicketInvalidate(ticket) {
      this.ticketInvalidateId = ticket.id
      this.ticketInvalidateConfirm = true
    },
    invalidateTicket() {
      this.invokeInvalidate(this.ticketInvalidateId)
        .finally(() => (this.ticketInvalidateConfirm = false))
        .then(() => this.getTickets())
        .catch((err) => {
          this.errorSnackbar = true
        })
    },
    getTickets() {
      this.loading = true

      this.invokeGetTickets()
        .finally(() => (this.loading = false))
        .then((result) => {
          this.tickets = result ? result : []
        })
        .catch((err) => {
          this.errorSnackbar = true
        })
    },
  },
  mounted() {
    this.getTickets()
  },
}
</script>
