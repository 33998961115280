<template>
  <div>
    <template v-if="!$route.meta.allowAnonymous">
      <v-app class="grey lighten-4">
        <Navbar />
        <v-content class="mx-4 mb-4">
          <router-view></router-view>
        </v-content>
      </v-app>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  components: { Navbar },
  name: 'App',
  data() {
    return {}
  },
}
</script>
